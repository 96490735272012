import React, { useState, useEffect } from "react";
import MobMenuBar from "../Common/MobMenuBar";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import SideBarMenu from "../Common/SideBarMenu";
import Header from "../Common/Header";
import rightArrow from "../../assets/images/icons/Icon ionic-ios-arrow-down.png";
import timeIcon from "../../assets/images/icons/Icon feather-clock.png";
import API from "../../Api/Api";
import moment from "moment";
import Button from 'react-bootstrap/Button';
import { NavLink, useNavigate } from "react-router-dom";
import RouteDetails from "../../Route/RouteDetails"

const Sessions = () => {
  const [activeIndex, setIndex] = useState(0);
  const [UpcommingList, setUpcommingList] = useState([]);
  const [Session_Summary, setSession_Summary] = useState([]);
  const [loadingUpcomingLst, setLoadingUpcomingList] = useState(false);
  const [loadingSessionSummary, setLoadingSessionSummary] = useState(false);
  const navigate = useNavigate()


  const handleTabs = (e) => {
    setIndex(e);
  };
  useEffect(() => {
    upcomingListfun();
    getSessionSummary_List();
  }, []);
  //api call for upcommming session

  const upcomingListfun = () => {
    setLoadingUpcomingList(true)
    try {
      API.Session.upcomingSessionList().then((response) => {
        if (response.data.status === 1) {
          console.log("upcomming", response?.data?.data_all);
          setUpcommingList(response?.data?.data_all);
        }
        setLoadingUpcomingList(false)
      });
    } catch (error) {
      setLoadingUpcomingList(false)
      console.log(error);
    }
  };

  //APi call for Session_History
  const getSessionSummary_List = () => {
    setLoadingSessionSummary(true)
    try {
      API.Session.sumarySessionList().then((response) => {
        if (response.data.status === 1) {
          console.log("===============", response?.data?.data_all?.reverse());

          setSession_Summary(response?.data?.data_all);
        }
        setLoadingSessionSummary(false);
      });
    } catch (error) {
      setLoadingSessionSummary(false);
      console.log(error);
    }
  };






  return (
    <div className="app-container">
      <div className="side-menu-panel">
        <div className="menu-panel-logo">
          <img className="logo" src={rtylifeLogo} alt="logo" />
        </div>
        <div>
          <SideBarMenu></SideBarMenu>
        </div>
      </div>
      <div className="content">
        <Header></Header>
        <MobMenuBar></MobMenuBar>
        <div className="sub-head px-3 py-2">
          <div className='w-100 h-head d-flex justify-content-between align-items-center'>
            <p className="sub-heading mb-0">Sessions</p>
            <Button type="submit" className="button_common notes-btn btn mt-2 mb-2 btn-primary btn btn-primary px-3"><NavLink className={"text-decoration-none"} to={`../${RouteDetails?.Auth?.MyCalender}/?key=true`}>Book a Session </NavLink></Button>
          </div>
          {/*
           <p className="sub-lbl">
              Total Number of Session Completed: 1000 Hours
            </p>
          */}
        </div>
        <div className="common-tabs" style={{ width: "100%" }}>
          <div
            className="common-tab-item cursor-pointer"
            onClick={() => handleTabs(0)}
            style={
              activeIndex == 0
                ? { borderBottom: "2px solid rgb(245,189,64)" }
                : null
            }
          >
            Upcoming Session
          </div>
          <div
            className="common-tab-item cursor-pointer"
            onClick={() => handleTabs(1)}
            style={
              activeIndex == 1
                ? { borderBottom: "2px solid rgb(245,189,64)" }
                : null
            }
          >
            Summary
          </div>
        </div>
        {activeIndex === 0 ? (
          <>
            <div className="client-name-list px-3 px-md-0">
              {loadingUpcomingLst ?
                <div className="alert alert-warning" role="alert">
                  Loading...
                </div>
                :
                UpcommingList?.length === 0 ?
                  <div className="alert alert-warning" role="alert">
                    Data for the upcoming session not found.
                  </div>
                  :
                  UpcommingList.map((val, i) => {
                    return (
                      <div
                        style={{ padding: "11px 15px" }}
                        className="client-name-card d-flex flex-row round-card align-items-center"
                        key={i}
                      >
                        <div
                          style={{
                            fontSize: "13px",
                            background: "#CBC5BD",
                            borderRadius: "27px",
                          }}
                          className="time-div"
                        >
                          <strong>{val?.timestart}</strong>
                        </div>
                        <span className="ms-3">
                          {moment(val.date).format("ll")}
                        </span>
                        <label
                          style={{ fontWeight: "500" }}
                          className="session-user-lbl  mx-2 my-1"
                        >
                          {val?.client[0]?.name} {val?.client[0]?.last_name}
                        </label>
                        <img src={timeIcon} alt="time" className="time-icon" />
                      </div>
                    );
                  })}
            </div>
          </>
        ) : (
          <>
            <div className="client-name-list px-3 px-md-0">
              <div
                className="d-flex justify-content-center"
                style={{ width: "100%" }}
              >
              </div>
              {loadingSessionSummary ?
                <div className="alert alert-warning" role="alert">
                  Loading...
                </div>
                :
                Session_Summary?.length === 0 ?
                  <div className="alert alert-warning" role="alert">
                    Data for the session summary not found.

                  </div>
                  :
                  Session_Summary?.map((val, i) => {
                    return (
                      <div
                        style={{ padding: "11px 15px", background: "#E5DFD7" }}
                        className="client-name-card d-flex flex-col"
                        key={val._id}
                        onClick={() => navigate(`../${RouteDetails?.Auth?.SessionDetails}/${val?._id}`)}
                      >
                        <div className="d-flex justify-content-between my-2">

                          <label style={{ fontSize: "12px" }}>
                            {moment(val.date).format("ll")}
                          </label>
                          <label style={{ fontSize: "12px" }}>
                            Published <label className="dot green"></label>
                          </label>
                        </div>
                        <div className="d-flex justify-content-between align-items-center ">
                          <div className="summary-time-name d-flex align-items-center">
                            <div
                              style={{ fontSize: "13px", background: "#CBC5BD" }}
                              className="time-div"
                            >
                              <strong>{val?.timestart?.split(' ')[0]}</strong> | {val?.timestart.split(' ')[1]}
                            </div>
                            <label
                              className="session-user-lbl mx-3"
                              style={{ width: "180px", fontWeight: "500" }}
                            >
                              {val?.client[0]?.fullname}
                            </label>
                          </div>

                          <img
                            style={{ top: "63px", right: "4%" }}
                            src={rightArrow}
                            className="right-arr"
                            alt="logo"
                          />
                        </div>

                      </div>
                    );
                  })}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Sessions;
